/* You can add global styles to this file, and also import other style files */
@import 'material-symbols';
@import '../../shared/styles/styles.scss';

html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
